import "./style.module.less"

import { Icon } from "@components/Icons"
import PixExpirationTimer from "@components/PixExpirationTimer"
import SuccessAnimation from "@components/SuccessAnimation"
import TranslateWrapper from "@components/TranslateWrapper"
import { useStores } from "@hooks/use-stores"
import { getActivePlans } from "@utils/plans"
import { Button, Col, Row } from "antd"
import { navigate } from "gatsby"
import _get from "lodash/get"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"

import translate from "../../i18n"
import UserPlan from "../../modules/Registration/SubscriberRegistration/UserPlan"
import { getTask, startTask, stopAllPixTasks } from "../../utils/taskSchedulers"
import SelectedPlanCard from "./components/SelectedPlanCard"
import styles from "./style.module.less"

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const PixPage = (props) => {
  const [pixPayment, setPixPayment] = useState()
  const [pixTimer, setPixTimer] = useState(0)
  const [plans, setPlans] = useState([])
  const [selectedPlan, setSelectedPlan] = useState()
  const [codeCoupon, setCodeCoupon] = useState("")
  const [typeCoupon, setTypeCoupon] = useState(null)
  const [valueCoupon, setValueCoupon] = useState(0)
  const [loading, setLoading] = useState(false)
  const prevPix = usePrevious(pixPayment)

  const { registrationSubscriber, authSite } = useStores()
  const { user } = authSite
  const { generateSubscribedPixCode, verifyPixPayment, listPlans } =
    registrationSubscriber

  const handleGetPlans = useCallback(async () => {
    const response = await listPlans()
    const plans = _get(response, "plans", [])
    const activePlans = getActivePlans(plans)

    setPlans(activePlans)
  })

  useEffect(() => {
    handleGetPlans()
  }, [])

  useEffect(() => {
    if (prevPix !== pixPayment) {
      if (pixPayment && !prevPix && pixPayment.status !== "CONCLUIDA") {
        startPaymentVerification()
      } else if (!pixPayment) {
        handleClearPix()
      }
    }
  }, [pixPayment])

  const handleClearPix = () => {
    stopAllPixTasks()
    setPixPayment(undefined)
    setPixTimer(0)
    setSelectedPlan(undefined)
  }

  const handlePixPaymentApproved = (payment) => {
    setPixPayment(payment)
    stopAllPixTasks()
    setPixTimer(0)
    setLoading(false)
  }

  const startPaymentVerification = () => {
    let res = null
    const taskName = `pix-${pixPayment.txid}-${new Date().getTime()}`
    if (!getTask(taskName)) {
      startTask(
        taskName,
        async () => {
          res = await verifyPixPayment(pixPayment.txid)
          if (res && res.status === "CONCLUIDA") {
            handlePixPaymentApproved(res)
          }
        },
        10000
      )
    }
  }

  const handleCreatePixPayment = async () => {
    setLoading(true)
    const payment_data = {
      transaction_amount: selectedPlan.amount / 100,
      description: selectedPlan.description,
      payment_method_id: "pix",
      payer: {
        email: _get(user, "email", ""),
        first_name: _get(user, "name", ""),
        last_name: "",
        identification: {
          type: "CPF",
          number: _get(user, "documentNumber", ""),
        },
        address: {
          zip_code: _get(user, "zipcode", ""),
          street_name: _get(user, "street", ""),
          street_number: _get(user, "number", ""),
          neighborhood: _get(user, "district", ""),
          city: _get(user, "city", ""),
          federal_unit: _get(user, "state", ""),
        },
      },
      subscriberId: user.id,
      planCode: selectedPlan.code,
      subscriptionCode: "PIX-SUB",
      planIntervalUnit: selectedPlan.interval.unit,
      planIntervalLength: selectedPlan.interval.length,
      // userPayload: userPayload,
    }

    const res = await generateSubscribedPixCode(payment_data)
    setPixPayment(res)
    const time = new Date()
    time.setSeconds(time.getSeconds() + 600) // 10 minutes timer
    setPixTimer(time)
    setLoading(false)
  }

  const handlePageSelected = ({ plan }) => {
    setSelectedPlan(plan)
  }

  function handleClearPlan() {
    handleClearPix()
  }

  return (
    <TranslateWrapper>
      <div className="page">
        <div
          className="page__wrapper"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <br />
          <br />
          {!selectedPlan && (
            <p className="tc">
              <h4 className="f14 normal scorpion-main">
                {translate("pixActivatePlanSelect")}
              </h4>
              <br />
            </p>
          )}
          {!selectedPlan && (
            <Row>
              <br />
              <br />
              <UserPlan
                handlePageSelected={handlePageSelected}
                registerFormValues={{}}
                plans={plans}
              />
            </Row>
          )}
          <br />
          <div className="tc mb24" style={{ width: "60%" }}>
            {selectedPlan && _get(pixPayment, "status", "") !== "CONCLUIDA" && (
              <SelectedPlanCard
                selectedPlan={selectedPlan}
                discount={valueCoupon}
                typeDiscount={typeCoupon}
                codeCoupon={codeCoupon}
                switchPlanClick={handleClearPlan}
              />
            )}
          </div>
          <br />

          <Row>
            {!pixPayment && selectedPlan && (
              <p className="tc">
                <h4 className="f14 normal scorpion-main">
                  {translate("pixDescriptionTwo")}
                </h4>
                teste
                <br />
                <Button
                  type="primary"
                  className="button-save"
                  onClick={handleCreatePixPayment}
                  loading={loading}
                >
                  {translate("pixGenerateQRCode")}
                </Button>
              </p>
            )}
          </Row>
          <Row>
            <Col>
              {pixPayment && pixPayment.status !== "CONCLUIDA" && (
                <div className="tc mb24">
                  <h2 className="scorpion-main">
                    {translate("pixExpiration")}
                  </h2>
                  <PixExpirationTimer
                    expiryTimestamp={pixTimer}
                    onExpire={handleClearPix}
                    paymentId={pixPayment.txid}
                  />
                  <br />
                  <img
                    alt="Pix QRCode"
                    src={`${_get(pixPayment, "imagemQrcode", "")}`}
                    className={styles.pixContainer}
                  />
                  <br />
                  <span className="f14 normal scorpion-main">
                    {translate("or")}
                  </span>
                  <br />
                  <span className="f14 normal scorpion-main">
                    {translate("clipboardPix")}
                  </span>
                  <br />
                  <div className="f12 normal scorpion-main">
                    {_get(pixPayment, "qrcode", "")}
                  </div>
                  <CopyToClipboard text={_get(pixPayment, "qrcode", "")}>
                    <Button type="text">
                      <div className="flex items-center justify-center">
                        <Icon name="Clipboard" />
                        <span className="fw5 f12 scorpion-main ml8">
                          {translate("copyCode")}
                        </span>
                      </div>
                    </Button>
                  </CopyToClipboard>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {pixPayment && pixPayment.status === "CONCLUIDA" && (
                <SuccessAnimation />
              )}
            </Col>
          </Row>
          <Row>
            {_get(pixPayment, "status", "") === "CONCLUIDA" && (
              <p className="tc">
                <h6 className="f14 normal scorpion-main">
                  {translate("pixActivateContinueText")}
                </h6>
                <br />
                <Button
                  type="primary"
                  className="button-save"
                  onClick={() => {
                    navigate("/feed")
                  }}
                >
                  {translate("pixActivateContinueButton")}
                </Button>
              </p>
            )}
          </Row>
        </div>
      </div>
    </TranslateWrapper>
  )
}

export default PixPage
